import React from "react";
import "./styles.css";
import SingleClient from "./SingleClient";

import uniliver from "../../assets/img/clients/uniliver.svg";
import nestle from "../../assets/img/clients/nestle.jpg";
import bat from "../../assets/img/clients/logo_BAT_9T5FQ2.png";
import jti from "../../assets/img/clients/jti.svg";
import reckitt from "../../assets/img/clients/reckitt.svg";
import mgi from "../../assets/img/clients/mgi.png";

import square from "../../assets/img/clients/square.png";
import aci from "../../assets/img/clients/aci.png";
import skf from "../../assets/img/clients/skf.png";
import renata from "../../assets/img/clients/renata.png";
import incepta from "../../assets/img/clients/incepta.png";
import beximco from "../../assets/img/clients/beximco.png";

import pgcb from "../../assets/img/clients/pgcb.png";
import egcb from "../../assets/img/clients/egcb.png";
import bifpcl from "../../assets/img/clients/bifpcl.webp";
import payra from "../../assets/img/clients/payra.png";
import sembcorp from "../../assets/img/clients/sembcorp.svg";

import bsrm from "../../assets/img/clients/bsrm.png";
import akij from "../../assets/img/clients/akij.png";
import lafargeholcim from "../../assets/img/clients/lafargeholcim.png";
import crown_cement from "../../assets/img/clients/crown_cement.svg";
import gildan from "../../assets/img/clients/gildan.png";
import robi from "../../assets/img/clients/robi.jpg";
import chevron from "../../assets/img/clients/chevron.png";

// import beximco from "../../assets/img/clients/bpl-logo.png";
// import acme from "../../assets/img/clients//acmeslogo.png";
// import walton from "../../assets/img/clients/download.png";
// import dbl from "../../assets/img/clients/dbl.svg";
// import beacon from "../../assets/img/clients/Beacon-Pharma-PLC-Logo.png";
// import posh from "../../assets/img/clients/posh.png";

const OurClients = () => {
  return (
    <div className="container p-5" id="our_clients">
      <div className="section_header">Our Clients</div>
      <br />
      <hr />
      <br />

      <div className="row">
        <div className="col-md-2 col-6">
          <SingleClient imageLink={uniliver} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={bat} />
        </div>

        <div className="col-md-2 col-6">
          <SingleClient imageLink={nestle} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP9qYjOYQ2_l8rv7W01IW-JdASanbetxY6hw&s" />
        </div>

        <div className="col-md-2 col-6">
          <SingleClient imageLink={reckitt} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={mgi} />
        </div>

        <div className="col-md-2 col-6">
          <SingleClient imageLink={square} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={aci} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={chevron} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={renata} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={incepta} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={beximco} />
        </div>

        <div className="col-md-2 col-6">
          <SingleClient imageLink={pgcb} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={egcb} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={bifpcl} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={payra} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={sembcorp} />
        </div>
        <div className="col-md-2 col-6">
          {" "}
          <SingleClient imageLink={skf} />
        </div>

        <div className="col-md-2 col-6">
          <SingleClient imageLink={bsrm} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={akij} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={lafargeholcim} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={crown_cement} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={gildan} />
        </div>
        <div className="col-md-2 col-6">
          <SingleClient imageLink={jti} />
        </div>
      </div>
      <br />
      <div className="text-center">
        <a href="#" className="btn see_more_btn">
          See More
        </a>
      </div>
    </div>
  );
};

export default OurClients;
