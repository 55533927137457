import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import "./styles.css";
import SingleProductMini from "../SingleProductMini";
import CategoryMenu from "../CategoryMenu";
import CopyrightComponent from "../Copyright";

const ProductDetails = () => {
  const { productId } = useParams();

  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);

  const fetchProductDetails = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/${productId}`
      );

      console.log(response.data.data);
      setProduct(response.data.data);

      fetchProducts(response.data.data.category_id, response.data.data.id);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProducts = async (categoryId, productIdToExclude) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productsByCategory/${categoryId}?limit=3&productIdToExclude=${productIdToExclude}`
      );

      setProducts(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [productId]);

  return (
    <>
      <div>
        <CategoryMenu />
        <br />
        <br />
        <br />
        <section className="py-5 product_details_section">
          <div className="container">
            <div className="row gx-5">
              <aside className="col-lg-6">
                <div className="border rounded-4 mb-3 d-flex justify-content-center align-items-center img_container">
                  <a
                    data-fslightbox="mygalley"
                    className="rounded-4"
                    target="_blank"
                    data-type="image"
                    href={
                      process.env.REACT_APP_GALLERY_URL + "/" + product.image
                    }
                  >
                    <img
                      className=""
                      src={
                        process.env.REACT_APP_GALLERY_URL + "/" + product.image
                      }
                    />
                  </a>
                </div>
              </aside>
              <main className="col-lg-6">
                <div className="ps-lg-3">
                  <h4 className="title text-dark">{product.title}</h4>
                  <br />
                  <div className="price mb-3">
                    {product.price !== null && (
                      <h6>Price: {product.price} BDT</h6>
                    )}
                  </div>
                  <div className="order">
                    <button
                      style={{
                        backgroundColor: "#3b81b1", // Green color
                        color: "#fff", // White text
                        fontSize: "16px", // Font size
                        padding: "10px 20px", // Padding around the text
                        border: "none", // Removing the default border
                        borderRadius: "5px", // Rounded corners
                        cursor: "pointer", // Pointer cursor on hover
                        fontWeight: "bold", // Bold text
                        transition: "background-color 0.3s ease", // Smooth background color transition on hover
                      }}
                    >
                      <a
                        href={`tel:+8801710494433`} // Using the 'tel' protocol to initiate a call
                        style={{
                          textDecoration: "none", // Removing underline
                          color: "#fff", // Making the link text white
                        }}
                      >
                        Call for Order
                      </a>
                    </button>
                    <p className="pt-2">+8801710494433</p>
                  </div>

                  <div className="row">
                    <dt className="col-3">Brand</dt>
                    <dd className="col-9">{product?.brand?.name || ""}</dd>
                  </div>
                  <br />
                  <br />
                </div>
              </main>
            </div>
          </div>
        </section>
        {/* content */}
        <section className="bg-light border-top py-4">
          <div className="container">
            <div className="row gx-4">
              <div className="col-lg-8 mb-4">
                <div className="border rounded-2 p-5 bg-white">
                  {/* Pills navs */}

                  {/* Pills content */}
                  <div className="tab-content" id="ex1-content">
                    <div
                      className="tab-pane fade show active"
                      id="ex1-pills-1"
                      role="tabpanel"
                      aria-labelledby="ex1-tab-1"
                    >
                      <div
                        style={{ fontSize: "14px" }}
                        dangerouslySetInnerHTML={{
                          __html: product.description,
                        }}
                      />
                    </div>
                  </div>
                  {/* Pills content */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="px-0 border rounded-2 shadow-0">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Similar items</h5>
                      <br />
                      {products &&
                        products.map((element) => {
                          return (
                            <div className="">
                              <SingleProductMini
                                productId={element.id}
                                image={
                                  process.env.REACT_APP_GALLERY_URL +
                                  "/" +
                                  element.image
                                }
                                title={element.title}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CopyrightComponent />
      </div>
    </>
  );
};

export default ProductDetails;
